import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import GalitLogo from '../images/logo.png';
import Link from '@material-ui/core/Link';
import hyperLogo from "../images/hyperlogo.jpg";

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
        backgroundColor: '#f0f4f5',
        width: '100%',
    },
    toolbarTitle: {
        flex: 1,
        [theme.breakpoints.down("xs")]: {
            fontSize: '90%'
        },
    },
    toolbarSecondary: {
        //justifyContent: 'space-between',
        //overflowX: 'auto',
        textAlign: 'center',
        display: 'block'
    },
    toolbarLink: {
        padding: theme.spacing(3),
        //flexShrink: 0,
        textAlign: 'center',
        //marginRight: '2px'
        //marginRight: 'auto'
    },
    linkText: {
        fontSize: '25px',
        [theme.breakpoints.down("xs")]: {
            fontSize: '20px'
        },
        margin: '0 auto',
    },
    navBar: {
        alignItems: 'center',
        margin: '0 auto'
    },
    logo: {
        marginRight: theme.spacing(5)
    },
    mainFeaturedPost: {
        width: '1280px',
        //marginTop: '8%',
        [theme.breakpoints.down("xs")]: {
            width: '100%'
        },
        boxShadow: '2px 2px 10px 0'
    }
}));

export default function Header(props) {
    const classes = useStyles();
    const { sections, title } = props;

    return (
        <React.Fragment>
            <div className={classes.navBar}>
            <Toolbar className={classes.toolbar}>
                <Typography
                    component="h2"
                    variant="h4"
                    color="inherit"
                    align="center"
                    noWrap
                    className={classes.toolbarTitle}
                >
                    <strong>{title}</strong>
                </Typography>
                {/*<img src={GalitLogo} alt="galit-logo" width="150px" className={classes.logo}/>*/}

            </Toolbar>
                <img className={classes.mainFeaturedPost} src={hyperLogo} alt="mainFeaturedPost" />
            <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary} dir='rtl'>
                {sections.map((section) => (
                    <Link
                        color="inherit"
                        noWrap
                        key={section.title}
                        variant="body2"
                        href={section.url}
                        className={classes.toolbarLink}
                        dir="rtl"
                    >
                        <Button className={classes.linkText}>
                        {section.title}
                        </Button>
                    </Link>

                ))}
            </Toolbar>
            </div>
        </React.Fragment>
    );
}

Header.propTypes = {
    sections: PropTypes.array,
    title: PropTypes.string,
};
