import React from "react";
import { MDBCarousel, MDBCarouselCaption, MDBCarouselInner, MDBCarouselItem, MDBView, MDBMask, MDBContainer } from
        "mdbreact";

//import photos
import a1 from '../images/accessories/a1.png';
import a2 from '../images/accessories/a2.png';
import a3 from '../images/accessories/a3.png';
import a4 from '../images/accessories/a4.png';
import a5 from '../images/accessories/a5.png';
import a6 from '../images/accessories/a6.png';
import a7 from '../images/accessories/a7.png';
import a8 from '../images/accessories/a8.png';
import a9 from '../images/accessories/a9.png';
import a10 from '../images/accessories/a10.png';

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Typography from "@material-ui/core/Typography";


const CarouselPage = () => {
    return (
        <div className="sec_gallery">
        <MDBContainer>
            <MDBCarousel
                activeItem={1}
                length={10}
                showControls={true}
                showIndicators={true}
                className="z-depth-1"
            >
                <MDBCarouselInner className="gallery_item">
                    <MDBCarouselItem itemId="1">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a1}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="2">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a2}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="3">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a3}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="4">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a4}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="5">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a5}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="6">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a6}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="7">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a7}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="8">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a8}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="9">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a9}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                    <MDBCarouselItem itemId="10">
                        <MDBView>
                            <img
                                className="d-block w-100"
                                src={a10}
                                alt="Accessories"
                            />
                            <MDBMask overlay="black-slight" />
                        </MDBView>
                    </MDBCarouselItem>
                </MDBCarouselInner>
            </MDBCarousel>
        </MDBContainer>
        </div>
    );
}

export default CarouselPage;
