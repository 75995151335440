import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Markdown from './Markdown';
import Paper from "@material-ui/core/Paper/Paper";

import HyperFooter from '../images/galit4.jpg'

const useStyles = makeStyles((theme) => ({
    markdown: {
        ...theme.typography.body2,
        padding: theme.spacing(3, 0),
    },
    main_text: {
        //direction: 'rtl',
        textAlign: 'right',
    },
    AboutBox: {
        padding: theme.spacing(2),
        marginBottom: '3%',
        backgroundColor: theme.palette.grey[200],
    },
    services: {
        backgroundColor: theme.palette.grey[200],
        padding: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
        marginBottom: '3%',
    },
    HyperFooter: {
        width: '100%',
        boxShadow: '2px 2px 10px 0',
        [theme.breakpoints.down("xs")]: {
            height: '140px',
        },
    },
}));

export default function Main(props) {
    const classes = useStyles();
    const { title } = props;

    return (
        <Grid item xs={12} md={8}  dir="rtl" className="main_text">
            <Typography variant="h6" gutterBottom dir="rtl" >
                <h3><strong>{title}</strong></h3>
            </Typography>
            <Divider />

                    <p dir="rtl">
                        "אין לי מה ללבוש".... איך יכול להיות שלמרות שהארון שלנו עמוס ורווי, אנחנו עומדות מולו בתסכול גדול מידי יום? הסיבה היא שלמרות השפע, ואולי בגללו, אנחנו לא מצליחות לנהל את הסגנון האישי שלנו באופן עקבי ונכון, ולא מנצלות את מה שיש בארון בצורה המיטבית. כתוצאה מכך אנחנו לא ממקסמות את פוטנציאל ההופעה שלנו. <br/>
                        מצד שני, הארון שלנו מכיל המון אוצרות, פריטים מתקופות חיים שונות, פריטים בעלי ערך נוסטלגי ופריטים שמתכתבים עם צרכים שונים של היומיום שלנו. זה לא נכון לעשות re-start, לזרוק את כולו ולקנות הכל מחדש.<br/>
                        ולכן, מי שמרגישה שיש לה שפע של פריטים טובים בארון אך זקוקה לייעוץ לגבי שילובים חדשים ומתוחכמים יותר, מי שרוצה להבין מה מתוך הארון הקיים מחמיא לה (ומה לא), מי שרוצה להבין מה הולך עם מה ואיזה אקססוריז לשלב מתוך המלאי שכבר יש לה – זה הפתרון המושלם בשבילה.<br/>
                        <br/>
                        אני מנסה לחשוב למה אני אוהבת כל כך נעליים ולא מצליחה להסביר את התשוקה הזאת באופן הגיוני. כל מה שאני יודעת הוא שאני אוהבת גם לקנות בגדים ודברים אחרים אבל ההתרגשות שמגיעה אחרי קניית נעליים לא משתווה לאף קנייה אחרת.<br/>
                        <br/>
                        מינוף ההופעה יכול להיעשות על בסיס יומיומי. לא צריך להבין באופנה, לא צריך להיות בעלות גזרה מסויימת ולא צריך הרבה כסף כדי להתלבש באופן המשרת את מטרותינו ולצעוד בעולם עם ראש זקוף והרגשה פנימית טובה. כל אחת יכולה להבין טוב יותר את מבנה גופה ואילו בגדים מחמיאים לה, כל אחת יכולה לשפר את התדמית שלה בכמה צעדים פשוטים, וכולנו יכולות לצאת נשכרות אם נצליח להחצין את העושר הפנימי שלנו, אם נצליח לתרגם אותו לפריטי לבוש שמשרתים את גופנו ואישיותנו ולהימנע משליחת מסרים הפוגעים בתדמית ומסבים לנו נזק.<br/>

                        סטיילינג אישי מעורר המון חומר למחשבה, על היופי בשוני בין אחד לשני וליופי בכלל<br/>
                        זה האתגר שלי כסטייליסטית להלביש אותך בהתאם לאישיותך וגם האתגר לארגן לך ארון חלומי אחד עם בגדים נכונים ויפים ובלי לואי ויטון אחד בתוכו (בעצם, למה לא?)<br/>
                    </p>
            <Paper elevation={0}  dir="rtl">
            <div className={classes.services }>
                {/*<Typography variant="h6" gutterBottom dir="rtl">*/}
                {/*    <strong>השירותים שאני נותנת:</strong>*/}
                {/*</Typography>*/}
                {/*<Typography dir="rtl">*/}
                {/*    <ul>*/}
                {/*        <li>סטיילינג אישי וייעוץ תדמית</li>*/}
                {/*        <li>סידור ארון</li>*/}
                {/*        <li>ליווי בקניות חכמות ונכונות</li>*/}
                {/*        <li>סדנאות סטיילינג</li>*/}
                {/*        <li>מכירת הבגדים האישיים שלי כחדשים, במחירים קטנים</li>*/}
                {/*    </ul>*/}
                {/*</Typography>*/}
                <img src={HyperFooter} className={classes.HyperFooter} alt="HyperFooter"/>
            </div>
            </Paper>
        </Grid>
    );
}

