import React, {ReactPropTypes as PropTypes} from 'react';
import './App.css';
import NavBar from './components/navbar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import Email from '@material-ui/icons/Email';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Phone from '@material-ui/icons/Phone';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Main from './components/Main';
import Sidebar from './components/SideBar';
import Footer from './components/Footer';
import Gallery from './components/Gallery';
import SecGallery from './components/SecGallery';
 import hyperLogo from './images/hyperlogo.jpg';
import Fab from "@material-ui/core/Fab";
import Zoom from "@material-ui/core/Zoom";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Divider from "@material-ui/core/Divider";

import {Parallax} from "react-parallax";


const useStyles = makeStyles((theme) => ({
    mainGrid: {
        marginTop: theme.spacing(3),
    },
    galleryGrid: {
        marginTop: theme.spacing(6),
        width: '40%',
        height: '100%',
        marginLeft: '30%',
        marginBottom: theme.spacing(1),
        textAlign: 'center',
        [theme.breakpoints.down("xs")]: {
            width: '100%',
            marginLeft: '0',
        },
    },
    root: {
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: '9999',
    },
    divider: {
        marginTop: theme.spacing(4),
    },
    body: {
        backgroundColor: '#aed1d0',
    },
    container: {
        backgroundColor: '#fafafa',
        [theme.breakpoints.down("xs")]: {
            backgroundColor: '#f0f4f5',
        },
        boxShadow: '2px 2px 10px 0',
    },
    mainFeaturedPost: {
        position: 'relative',
        backgroundColor: theme.palette.grey[800],
        //color: theme.palette.common.white,
        marginBottom: theme.spacing(4),
        width: '100%',
        height: '100%',
        [theme.breakpoints.down("xs")]: {
            backgroundSize: 'cover'
        },
    },
    galleryText: {
        margin: '0 auto',
        marginBottom: theme.spacing(4),
    },
    nav: {
        textAlign: 'center'
    }
}));

const sections = [
    { title: 'גלריה', url: '#gallery' },
    { title: 'אודות', url: '#about' },
    { title: 'צור קשר', url: '#social' },
];



const sidebar = {
    title: 'אודות',
    description:
        'היום כמו כל יום במשך המון שנים, האופנה היא דרך חיים והכל מתחבר לי ביחד וטוב לי על הלב. בעבר הייתי מעצבת גרפית, במשך הרבה שנים הייתי גרפולוגית, ובמשך 10 השנים האחרונות כל כולי בסטיילינג.\n' +
        '\n' +
        'אני סטייליסטית אישית, יועצת תדמית ומובילת דעת קהל בעולם האופנה.\n' +
        'אני אוהבת לחשוב שדרך הסטיילינג אני מצליחה לטרוף ת‘חיים, מגדירה את סגנון הלבוש כחותמת אישית. המראה שלי מספר את הסיפור האישי שלי, מי אני? מה אני רוצה לשדר? וזה האתגר שלי כסטייליסטית, לתרגם את אישיות הלקוח שלי בסגנון הלבוש שלו. רבים חושבים ששירות של סטייליסטית זה נחלתם של עשירים בלבד, לא נכון!!! לכל אישה מגיע להשקיע בעצמה, דימוי הגוף חשוב ובטחון עצמי, ואני מעניקה שירות שהמחירים מותאמים לכל כיס.\n' +
        'אני מקיימת סדנאות סטיילינג, מפגש חווייתי עם הרבה טיפים ותובנות על סטיילינג, עם מצגת מהממת והדגמות\n' +
        'אני מוכרת את הבגדים האישיים שלי. אם אתן, הנשים, שאוהבות להתלבש ומעוניינות לקנות בגדי סטייליסטית במחירים קטנים וגם נוכל לעזור לקיימות העולם שלנו... אני לובשת מידה Large ונועלת נעליים מידה 39. יש לי להציע בגדים: שמלות, חצאיות, חולצות, ג‘קטים, חורף וקיץ. כמו כן, אקססוריס, תיקים, חגורות, תיקים, נעלי קז‘ואל ונעלים קלאסיות.\n',

    social: [
        { name: 'Instagram', icon: InstagramIcon, src: 'https://www.instagram.com/galit_style/' },
        { name: 'Facebook', icon: FacebookIcon, src: 'https://www.facebook.com/Galit-style-%D7%92%D7%9C%D7%99%D7%AA-%D7%9C%D7%97%D7%9E%D7%9F-%D7%A1%D7%98%D7%99%D7%99%D7%9C%D7%99%D7%A0%D7%92-%D7%90%D7%99%D7%A9%D7%99-1111615862305524/' },
        { name: 'Email', icon: Email, src: 'mailto:galitlachman1971@gmail.com'  },
        { name: 'WhatsApp', icon: WhatsApp, src: 'https://wa.me/972526935600'  },
        { name: 'Phone', icon: Phone, src: 'tel:+972526935600'  },

    ],
};


function ScrollTop(props) {
    const { children, window } = props;
    const classes = useStyles();
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');

        if (anchor) {
            anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    };

    return (
        <Zoom in={trigger}>
            <div onClick={handleClick} role="presentation" className={classes.root}>
                {children}
            </div>
        </Zoom>
    );
}
const insideStyles = {
    background: "white",
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)"
};

export default function App() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline/>
            <div className={classes.body}>
                <div className={classes.nav}>
                <NavBar title="Fashion is what you buy. Style is what you do with it" sections={sections}/>
                </div>
            <Container maxWidth="lg" className={classes.container}>
                <main className={classes.main}>
                    {/*<Parallax bgImage={hyperLogo} strength={500} className={classes.mainFeaturedPost}>*/}
                    {/*    <div style={{ height: 500 }}>*/}
                    {/*        /!*<div style={insideStyles}>galit</div>*!/*/}
                    {/*    </div>*/}
                    {/*</Parallax>*/}
                    <Divider id='gallery'/>
                    <Grid container spacing={5} className={classes.galleryGrid}>
                        <h2 className={classes.galleryText}><strong>Outfit of the day</strong></h2>
                        <Gallery/>
                    </Grid>
                    <Grid container spacing={5} className={classes.galleryGrid}>
                        <h2 className={classes.galleryText}><strong>Accessories and more</strong></h2>
                        <SecGallery/>
                    </Grid>
                    <br/>
                    <Grid container spacing={5} id='about' className={classes.mainGrid}>
                        <Main title="Styling Your Day" dir='rtl'/>
                        <Sidebar
                            title={sidebar.title}
                            description={sidebar.description}
                            social={sidebar.social}

                        />
                    </Grid>
                </main>
            </Container>
            <ScrollTop>
                <Fab color="primary" size="small" aria-label="scroll back to top" href="#">
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
            </div>
            <Footer title="גלית לחמן - סטיילינג אישי" description="AppWeb4U האתר עוצב ופותח על ידי " dir="rtl"/>

        </React.Fragment>
    );
}
