import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
    },
}));

export default function Sidebar(props) {
    const classes = useStyles();
    const { archives, description, social, title } = props;

    return (
        <Grid item xs={12} md={4}>
            <Paper elevation={0} className={classes.sidebarAboutBox} dir="rtl">
                <Typography variant="h6" gutterBottom dir="rtl">
                    <strong>{title}</strong>
                </Typography>
                <Typography dir="rtl">{description}</Typography>
            </Paper>
            <Typography variant="h6" gutterBottom className={classes.sidebarSection} id="social" dir="rtl">
                <strong>צור קשר</strong>
            </Typography>
            {social.map((network) => (
                <Link display="block" variant="body1" href={network.src} key={network} dir="rtl">
                    <Grid container direction="row" spacing={1} alignItems="center">
                        <Grid item>
                            <network.icon />
                        </Grid>
                        <Grid item>{network.name}</Grid>
                    </Grid>
                </Link>
            ))}
        </Grid>
    );
}

Sidebar.propTypes = {
    archives: PropTypes.array,
    description: PropTypes.string,
    social: PropTypes.array,
    title: PropTypes.string,
};
